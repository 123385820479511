import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import { NoSpace } from '@/libs/helpers'
import {
  BCol,
  BNavbarBrand,
  BImg,
  BRow,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BSpinner,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    BNavbarBrand,
    BImg,
    BRow,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      agree: false,
      fullname: '',
      customerPhone: '',
      userEmail: '',
      userPassword: '',
      confirmPassword: '',
      loading: false,

      submitErrors: '',

      required,
      email,

      passwordFieldTypePassword: 'password',
      passwordFieldTypeConfirmPassword: 'password',

      errorCharPassword: false,

      usernameTaken: '',
      emailTaken: '',
      data: [],

      // Existing
      userEmailExisting: '',
      modeNewUser: true,
      modeExistingUser: false,
      serviceTitle: '',
      serviceIsKomship: false,

      messageValidateNo: '',
      NoSpace,

      errorCoupon: false,
      couponCode: this.$route.query.code ? this.$route.query.code : '',
      couponCodeLengthError: false,
      couponNotFound: false,
      isAffiliate: false,
      affiliatorId: 0,

      phoneCodeList: ['+62'],
      phoneCode: '+62',
      isWhatsapp: null,
      requireCustomerPhone: false,
      errorMsgPassword: '',
    }
  },
  computed: {
    passwordToggleIconPassword() {
      return this.passwordFieldTypePassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirmPassword() {
      return this.passwordFieldTypeConfirmPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    register() {
      this.loading = true
      this.usernameTaken = ''
      this.emailTaken = ''
      this.serviceTitle = ''
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          if (this.couponCode.length > 0) {
            this.$http_komship_affiliate.get(`/v1/coupon/check-register?coupon_name=${this.couponCode}&member_email=${this.userEmail}`)
              .then(res => {
                this.error = ''
                this.$refs.inputkirimemail_email.value = this.userEmail
                this.$refs.inputkirimemail_full_name.value = this.fullname
                this.affiliatorId = res.data.data.affiliator_id
                this.$http_komship.post('/v1/register', {
                  full_name: this.fullname,
                  no_hp: this.customerPhone,
                  email: this.userEmail,
                  password: this.userPassword,
                  password_confirmation: this.confirmPassword,
                  affiliator_id: this.affiliatorId,
                }).then(response => {
                  const { data } = response
                  if (data.message === 'Akun Kamu telah terdaftar Komerce Hiring') {
                    this.emailTaken = 'The email has already been taken.'
                    this.loading = false
                    this.modeNewUser = false
                    this.modeExistingUser = true
                    this.userEmailExisting = this.userEmail
                    this.serviceTitle = 'Hiring Talent'
                    this.agree = false
                  } else if (data.message === 'Akun Kamu telah terdaftar Komship') {
                    this.emailTaken = 'The email has already been taken.'
                    this.loading = false
                    this.modeNewUser = false
                    this.modeExistingUser = true
                    this.userEmailExisting = this.userEmail
                    this.serviceTitle = 'Komship'
                    this.agree = false
                    this.serviceIsKomship = true
                  }
                  if (data.code !== 400) {
                    // this.loading = false
                    // const routeData = this.$router.resolve({ name: 'komship-register-validate' })
                    // window.open(routeData.href, '_blank')
                    this.$refs.submitformkirimemail.click()
                  }
                  if (data.message === 'Akun Kamu Terdaftar Sebagai Management, Silahkan Gunakan Email Lain.') this.$refs['modal-validate-existing-manajemen'].show()
                  if (data.message === 'Akun Kamu Terdaftar Sebagai Talent, Silahkan Gunakan Email Lain.') this.$refs['modal-validate-existing-manajemen'].show()
                  if (data.message === 'Akun Kamu Terdaftar Sebagai Talent Partner Komship, Silahkan Gunakan Email Lain.') this.$refs['modal-validate-existing-manajemen'].show()
                  if (data.data?.role_id === 3) this.$refs['modal-validate-existing-manajemen'].show()
                  if (data.data?.role_id === 5) this.$refs['modal-validate-existing-manajemen'].show()
                  this.loading = false
                }).catch(() => {
                  this.$toast({
                    component: ToastificationContentVue,
                    props: {
                      title: 'Gagal',
                      text: 'Gagal untuk register, silahkan coba lagi!',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  }, 2000)
                  this.loading = false
                })
              }).catch(err => {
                this.loading = false
                if (err.response.data.message === 'Not found.') {
                  this.couponNotFound = true
                }
                if (err.response.data.code === 409) {
                  this.isAffiliate = true
                }
              })
          } else {
            this.error = ''
            this.$refs.inputkirimemail_email.value = this.userEmail
            this.$refs.inputkirimemail_full_name.value = this.fullname
            this.$http_komship.post('/v1/register', {
              full_name: this.fullname,
              no_hp: this.customerPhone,
              email: this.userEmail,
              password: this.userPassword,
              password_confirmation: this.confirmPassword,
            }).then(response => {
              const { data } = response
              if (data.message === 'Akun Kamu telah terdaftar Komerce Hiring') {
                this.emailTaken = 'The email has already been taken.'
                this.loading = false
                this.modeNewUser = false
                this.modeExistingUser = true
                this.userEmailExisting = this.userEmail
                this.serviceTitle = 'Hiring Talent'
                this.agree = false
              } else if (data.message === 'Akun Kamu telah terdaftar Komship') {
                this.emailTaken = 'The email has already been taken.'
                this.loading = false
                this.modeNewUser = false
                this.modeExistingUser = true
                this.userEmailExisting = this.userEmail
                this.serviceTitle = 'Komship'
                this.agree = false
                this.serviceIsKomship = true
              }

              if (data.code !== 400) {
                // this.loading = false
                // const routeData = this.$router.resolve({ name: 'komship-register-validate' })
                // window.open(routeData.href, '_blank')
                this.$refs.submitformkirimemail.click()
              }
              if (data.message === 'Akun Kamu Terdaftar Sebagai Management, Silahkan Gunakan Email Lain.') this.$refs['modal-validate-existing-manajemen'].show()
              if (data.message === 'Akun Kamu Terdaftar Sebagai Talent, Silahkan Gunakan Email Lain.') this.$refs['modal-validate-existing-manajemen'].show()
              if (data.message === 'Akun Kamu Terdaftar Sebagai Talent Partner Komship, Silahkan Gunakan Email Lain.') this.$refs['modal-validate-existing-manajemen'].show()
              if (data.data?.role_id === 3) this.$refs['modal-validate-existing-manajemen'].show()
              if (data.data?.role_id === 5) this.$refs['modal-validate-existing-manajemen'].show()
              this.loading = false
            }).catch(() => {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Gagal',
                  text: 'Gagal untuk register, silahkan coba lagi!',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              }, 2000)
              this.loading = false
            })
          }
        } else {
          this.loading = false
        }
      })
    },
    togglePasswordVisibilityPassword() {
      if (this.passwordFieldTypePassword === 'password') {
        this.passwordFieldTypePassword = 'text'
      } else if (this.passwordFieldTypePassword === 'text') {
        this.passwordFieldTypePassword = 'password'
      }
    },
    togglePasswordVisibilityConfirmPassword() {
      if (this.passwordFieldTypeConfirmPassword === 'password') {
        this.passwordFieldTypeConfirmPassword = 'text'
      } else if (this.passwordFieldTypeConfirmPassword === 'text') {
        this.passwordFieldTypeConfirmPassword = 'password'
      }
    },
    validPassword() {
      if (this.userPassword.length === 0) {
        this.errorMsgPassword = 'Password harus diisi'
      } else if (this.userPassword.length < 8) {
        this.errorMsgPassword = '*Minimal 8 karakter'
      } else {
        this.errorMsgPassword = ''
      }
    },
    handleSpace(e) {
      const charCode = e.which ? e.which : e.keyCode
      if (charCode === 32) {
        this.errorMsgPassword = 'Tidak boleh memakai spasi'
        e.preventDefault()
      } else {
        this.errorMsgPassword = ''
      }
    },
    resetValidationUsername() {
      this.usernameTaken = ''
    },
    resetValidationEmail() {
      this.emailTaken = ''
    },
    registerExisting() {
      this.loading = true
      this.$refs.loginFormExisting.validate().then(success => {
        if (success) {
          this.$http.put('/register/partner-existing', {
            email: this.userEmailExisting,
          }).then(response => {
            this.loading = false
            this.$router.push({ name: 'komship-register-validate' })
          }).catch(() => {
            this.loading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk register, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.loading = false
        }
      })
    },
    formatLengthNo(e) {
      return String(e).substring(0, 14)
    },
    validateInputPhoneCustomer(e) {
      this.errorCharPassword = false
      this.messageValidateNo = ''
      const charCode = e.which ? e.which : e.keyCode
      if (this.customerPhone.length === 0) {
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 48) {
          e.preventDefault()
        }
        if (e.keyCode === 48) {
          e.preventDefault()
        }
        if (e.keyCode !== 56) {
          this.errorCharPassword = true
          e.preventDefault()
        }
      }
      if (e.keyCode === 46 || e.keyCode === 45 || e.keyCode === 43 || e.keyCode === 101) {
        e.preventDefault()
      }
    },
    formatterPhone(e) {
      this.customerPhone = ''
      this.customerPhonePaste = ''
      this.customerPhonePaste = e.clipboardData.getData('text').replace(/\D/g, '')
      if (this.customerPhonePaste.charAt(0) === '0') {
        this.customerPhonePaste = this.customerPhonePaste.substr(1, this.customerPhonePaste.length)
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(0, this.customerPhonePaste.length)
      }
      if (this.customerPhonePaste.charAt(0) === '6') {
        this.customerPhonePaste = this.customerPhonePaste.substr(2, this.customerPhonePaste.length)
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(0, this.customerPhonePaste.length)
      }
      if (this.customerPhonePaste.length > 9) {
        this.customerPhone = this.customerPhonePaste
        this.formatPhoneCustomer()
        this.checkValidationCustomerPhone()
      } else {
        this.customerPhone = this.customerPhonePaste
      }
    },
    valueFormatPhone(e) {
      while (!this.customerPhone.startsWith('8')) {
        if (this.customerPhone.length === 0) {
          this.messageValidateNo = ''
          break
        }
        this.customerPhone = this.customerPhone.substr(1)
      }
      if (this.customerPhone.length > 14) {
        this.customerPhone = this.customerPhone.substr(0, 14)
      }
    },
    async checkWhatsapp() {
      if (this.customerPhone.length >= 9) {
        this.messageValidateNo = ''
        await this.$http_komship.post(`/v1/check-wa-global?phone_no=${this.customerPhone.length >= 9 ? this.customerPhone : ''}`)
          .then(res => {
            const { data } = res.data
            this.isWhatsapp = data
          })
          .catch(error => {
            const { data } = error.response.data
            this.isWhatsapp = data
            if (error.response.data.code !== 1001) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Failure',
                  icon: 'AlertCircleIcon',
                  text: error,
                  variant: 'danger',
                },
              })
            }
          })
      } else {
        this.isWhatsapp = null
      }
    },
    checkValidationCustomerPhone() {
      this.errorCharPassword = false
      if (this.customerPhone === '') {
        this.messageValidateNo = 'Nomor wajib diisi, dimulai dari angka 8'
      } else if (this.customerPhone.length < 9) {
        this.messageValidateNo = 'Minimal 9 angka'
      } else {
        this.requireCustomerPhone = false
      }
    },
    formatPhoneCustomer: _.debounce(function () {
      if (this.customerPhone.length < 9) {
        this.isWhatsapp = null
      } else {
        this.checkWhatsapp()
      }
    }, 1000),
    closeModalValidateManajemen() {
      this.$refs['modal-validate-existing-manajemen'].hide()
    },
    couponValidation() {
      this.couponNotFound = false
      this.isAffiliate = false
      if (!this.couponCode.match(/^[a-z0-9]+$/i) && this.couponCode.length > 0) {
        this.errorCoupon = true
      } else {
        this.errorCoupon = false
      }
      if (this.couponCode.length < 4 && this.couponCode.length > 0) {
        this.couponCodeLengthError = true
      } else {
        this.couponCodeLengthError = false
      }
    },
    couponCodeFormat(e) {
      return e.toUpperCase()
    },
    regexCoupon(e) {
      this.couponCode = e.target.value.replace(/[^A-Za-z0-9]/g, '')
    },
  },
}
